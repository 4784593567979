import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Col } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import NavBar from "../components/navbar.js"
import HeadshotImage from "../assets/ArianaHead.png"

const Outer = styled.div`
  animation: fade 1.0s ease-in;

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: ${({ isDesktop }) => isDesktop ? '30px' : '18px'};
  padding-right: ${({ isDesktop }) => isDesktop ? '30px' : '18px'};
  background-color: white;
  color: black;
`;

const Title = styled.div`
  text-align: center;
  /* color: #1A344B; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 36px;
`;

const Subtitle = styled.div`
  text-align: center;
  /* color: #1A344B; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
`;

const Center = styled.div`
  text-align: center;
`;

const CenterItem = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Clickable = styled.a`
`;

const Headshot = styled.img`
  height: 260px;
  width: 220px;
  object-fit: cover;
  box-shadow: 0px 5px 25px 2px rgba(0, 0, 0, 0.55);
`;

const Section = styled.div`
  color: #373737;
  font-size: 18px;
  font-family: 'Lora', serif;
  padding-left: ${({ isDesktop }) => isDesktop ? '40px' : '0px'};
  padding-right: ${({ isDesktop }) => isDesktop ? '40px' : '0px'};
  max-width: 1000px;
`;

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

export default function About() {
  document.title = "About me";
  const history = useHistory()
  let [isDesktop, setIsDesktop] = useState(getWidth() > 1000);

  useEffect(() => {
    const resizeListener = () => {
      setIsDesktop(getWidth() > 1000)
    };
    window.addEventListener('resize', resizeListener);
    return () => { window.removeEventListener('resize', resizeListener); }
  }, [])

  return (
    <>
      <Col style={{padding: "0"}}>
        <NavBar />
        <Outer>
          <Col style={{padding: "0"}}>
            <Title>Who is Ariana Kim?</Title>
            <div style={{height: "50px"}} />
            <CenterItem>
              <Clickable href="http://linkedin.com/in/arianakim">
              <Headshot src={HeadshotImage} />
              </Clickable>
            </CenterItem>
            <div style={{height: "50px"}} />
            <Subtitle>Education</Subtitle>
            <div style={{height: "30px"}} />
            <Center>Harvard University, ALM Psychology & Certificate in Human Behavior</Center>
            <Center>University of Southern California, BA Communication & Emphasis in Cognitive Science</Center>
            <div style={{height: "50px"}} />
            <CenterItem>
              <Section isDesktop={isDesktop}>
                🌴 <b>Aloha!</b> ☀️
                <br /><br />
                I&apos;m Ariana Kim!
                <br /><br />
                <i>Who?</i>
                <br /><br />
                I majored in Communication with an emphasis in Cognitive
                Science and Philosophy at the University of Southern California. I grew up in Seoul and
                Honolulu then moved to Los Angeles for college. During my undergraduate years, I also had
                the opportunity to study abroad in Rome, Athens, and Havana. I&apos;m now based in the quaint
                neighborhood of Santa Clara in the San Francisco Bay Area.
                <br /><br />
                <i>My Career</i>
                <br /><br />
                4+ year experienced sales &amp; marketing technology professional with experience in
                a diversity of verticals: semiconductor, consumer technology, and cybersecurity. I&apos;ve also
                worked with companies in a variety of industries: system integrators, big data, cloud
                computing, SaaS, SMB, IT folks, B2B/B2C, defense, health, federal, education, etc!
                <br /><br />
                <i>What&apos;s that mean?</i>
                <br /><br />
                My latest roles include being a Sales and Marketing Development Manager at Intel, Small
                Business Marketing Strategist, as well as Executive Thought Leader Marketing. In these
                roles I&apos;ve covered anything from launching insights-led marketing campaigns to
                conducting UX research into business verticals and executing go-to-market strategies.
                Throughout all my roles, I&apos;ve been able to be very customer-centric from sales enablement
                to working with ecosystem partners.
                <br /><br />
                <i>Cool. What else?</i>
                <br /><br />
                #1: I love design in all forms (Fashion, UI/UX, Interior, all things HCI)!
                <br />
                #2: I enjoy working with middle and high school students to help them achieve their
                academic dreams. As a first-generation immigrant myself, bridging the gap for education
                access is one of my personal missions.
                <br />
                #3: Ask me about my book-of-the-month (hoping this will change to book of the week one
                day…)
              </Section>
            </CenterItem>
            <div style={{height: "80px"}} />
          </Col>
        </Outer>
      </Col>
    </>
  )
}
