import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Col } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import NavBar from "../components/navbar.js"

const Outer = styled.div`
  animation: fade 1.0s ease-in;

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }
  position: relative;
  padding-top: ${({ isDesktop }) => isDesktop ? '60px' : '30px'};
  padding-bottom: 50px;
  padding-left: ${({ isDesktop }) => isDesktop ? '60px' : '30px'};
  padding-right: ${({ isDesktop }) => isDesktop ? '60px' : '30px'};
  background-color: white;
  color: black;
`;

const Title = styled.div`
  text-align: ${({ isDesktop }) => isDesktop ? 'center' : 'center'};
  font-family: 'Montserrat', sans-serif;
  // font-weight: 600;
  font-size: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-transform: uppercase;
  letter-spacing: 0.2rem;
`;

const Subtitle = styled.div`
  text-align: ${({ isDesktop }) => isDesktop ? 'center' : 'center'};
  font-family: 'Lora', serif;
  font-size: 18px;
  // font-weight: 600;
  // text-transform: uppercase;
  letter-spacing: 0.02rem;
`;

const ItemList = styled.ol`
  margin: 0px;
	padding: 0px;
  /* padding-left: 10px;
  padding-right: 20px; */
  position: sticky;
  display: flex;
	flex-direction: column;
	align-items: center;
  /* flex-wrap: wrap;
  justify-content: space-around; */
  list-style: none;
`;

const Item = styled.a`
  position: relative;
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 10px;
  min-width: 320px;
  height: 320px;
  background-color: white;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, .3);
  border-radius: 8px;
`;

const ItemPhoto = styled.img`
  width: 320px;
  height: 320px;
  object-fit: cover;
  border-radius: 8px;
`;

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

export default function Projects() {
  document.title = "My Projects";
  const history = useHistory()
  let [isDesktop, setIsDesktop] = useState(getWidth() > 1000);

  useEffect(() => {
    const resizeListener = () => {
      setIsDesktop(getWidth() > 1000)
    };
    window.addEventListener('resize', resizeListener);
    return () => { window.removeEventListener('resize', resizeListener); }
  }, [])

  return (
    <>
      <Col style={{padding: "0"}}>
        <NavBar />
        <Outer isDesktop={isDesktop}>
          <Col style={{padding: "0"}}>
            <Title isDesktop={isDesktop}>My Projects</Title>
            <div style={{height: isDesktop ? "30px" : "30px"}} />
            <Subtitle isDesktop={isDesktop}>Pieces of writing I have crafted over the years covering a wide-range of industries and topics.</Subtitle>
						<div style={{height: isDesktop ? "100px" : "100px"}} />
            <ItemList>
							<a href="/projects/comm309_paper1.pdf">
								Politics of Communication Technologies - Instagram 
							</a>
							<br />
							<a href="/projects/comm309_paper2.pdf">
								TikTok and its Communication Rituals: Creating and Interpreting Messages in Communities
							</a>
							{/* <br />
							<a href="/projects/comm363_final_paper.pdf">
								Asian Representation in Hollywood: Gender Representation and Identity for Asian-Americans
							</a> */}
							<br />
							<a href="/projects/comm499_essay.pdf">
								Being a KPOP Fan as a Non-Korean: A Study of the BTS fandom ‘ARMY’ in the Philippines
							</a>
							<br />
							<a href="/projects/comm499_final_paper.pdf">
								KPOP International Female Fans and Self-Perception 
							</a>
							<br />
						</ItemList>
          </Col>
        </Outer>
        <div style={{height: "80px"}} />
      </Col>
    </>
  )
}
