import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Col } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import NavBar from "../components/navbar.js"

import FlexImg from "../assets/flex.png"
import GfImg from "../assets/gf.png"
import IntelImg from "../assets/intel.png"
import NbcUniversalImg from "../assets/nbc-universal.png"
import PacRimImg from "../assets/pac-rim.png"
import SamsungImg from "../assets/samsung.png"
import CnbImg from "../assets/city-national-bank.png"
import AkcImg from "../assets/ariana-kim-consulting.png"

const Outer = styled.div`
  animation: fade 1.0s ease-in;

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }
  position: relative;
  padding-top: ${({ isDesktop }) => isDesktop ? '60px' : '30px'};
  padding-bottom: 50px;
  padding-left: ${({ isDesktop }) => isDesktop ? '60px' : '30px'};
  padding-right: ${({ isDesktop }) => isDesktop ? '60px' : '30px'};
  background-color: white;
  color: black;
`;

const Title = styled.div`
  text-align: ${({ isDesktop }) => isDesktop ? 'center' : 'center'};
  font-family: 'Montserrat', sans-serif;
  // font-weight: 600;
  font-size: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-transform: uppercase;
  letter-spacing: 0.2rem;
`;

const Subtitle = styled.div`
  text-align: ${({ isDesktop }) => isDesktop ? 'center' : 'center'};
  font-family: 'Lora', serif;
  font-size: 18px;
  // font-weight: 600;
  // text-transform: uppercase;
  letter-spacing: 0.02rem;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const ItemList = styled.ol`
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 20px;
  position: sticky;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  list-style: none;
`;

const Item = styled.a`
  position: relative;
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 10px;
  min-width: 320px;
  height: 320px;
  background-color: white;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, .3);
  border-radius: 8px;
`;

const ItemPhoto = styled.img`
  width: 320px;
  height: 320px;
  object-fit: cover;
  border-radius: 8px;
`;

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

export default function Work() {
  document.title = "My Work";
  const history = useHistory()
  let [isDesktop, setIsDesktop] = useState(getWidth() > 1000);

  useEffect(() => {
    const resizeListener = () => {
      setIsDesktop(getWidth() > 1000)
    };
    window.addEventListener('resize', resizeListener);
    return () => { window.removeEventListener('resize', resizeListener); }
  }, [])

  return (
    <>
      <Col style={{padding: "0"}}>
        <NavBar />
        <Outer isDesktop={isDesktop}>
          <Col style={{padding: "0"}}>
            <Title isDesktop={isDesktop}>My Work Experience</Title>
            <div style={{height: isDesktop ? "30px" : "30px"}} />
            <Subtitle isDesktop={isDesktop}>Robust <b>problem-solver</b> with a <b>wide skillset</b> & <b>unique perspectives</b> brought from a variety of industries</Subtitle>
            <ItemList>
              <Item href="/work/intel">
                <ItemPhoto src={IntelImg} isDesktop={isDesktop}/>
              </Item>
              {/* <Item href="/work/ariana-kim-consulting">
                <ItemPhoto src={AkcImg} isDesktop={isDesktop}/>
              </Item> */}
              <Item href="/work/globalfoundries">
                <ItemPhoto src={GfImg} isDesktop={isDesktop}/>
              </Item>
              <Item href="/work/samsung">
                <ItemPhoto src={SamsungImg} isDesktop={isDesktop}/>
              </Item>
              <Item href="/work/city-national-bank">
                <ItemPhoto src={CnbImg} isDesktop={isDesktop}/>
              </Item>
              <Item href="/work/nbc-universal">
                <ItemPhoto src={NbcUniversalImg} isDesktop={isDesktop}/>
              </Item>
              <Item href="/work/pac-rim">
                <ItemPhoto src={PacRimImg} isDesktop={isDesktop}/>
              </Item>
              <Item href="/work/flex">
                <ItemPhoto src={FlexImg} isDesktop={isDesktop}/>
              </Item>
            </ItemList>
          </Col>
        </Outer>
        <div style={{height: "80px"}} />
      </Col>
    </>
  )
}
