import React, { useEffect } from "react"
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "./pages/home.js"
import About from "./pages/about.js"
import Contact from "./pages/contact.js"
import MessageSent from "./pages/message-sent.js"
import CatchAll from "./pages/catch-all.js"
import Work from "./pages/work.js"
import IndividualWork from "./pages/individual-work.js"
import Projects from "./pages/projects.js"

ReactGA.initialize("UA-208185374-1");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  })
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/work" component={Work} />
        <Route exact path="/work/:company" component={IndividualWork} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/message-sent" component={MessageSent} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/:id" component={CatchAll} />
      </Switch>
    </Router>
  )
}

export default App
