import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { Col } from "react-bootstrap"
import { useHistory, useParams, Redirect } from "react-router-dom"
import NavBar from "../components/navbar.js"

import FlexImg from "../assets/flex.png"
import GfImg from "../assets/gf.png"
import IntelImg from "../assets/intel.png"
import NbcUniversalImg from "../assets/nbc-universal.png"
import PacRimImg from "../assets/pac-rim.png"
import SamsungImg from "../assets/samsung.png"
import CnbImg from "../assets/city-national-bank.png"
import AkcImg from "../assets/ariana-kim-consulting.png"

const Outer = styled.div`
  animation: fade 1.0s ease-in;

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }
  position: relative;
  padding-top: ${({ isDesktop }) => isDesktop ? '50px' : '0px'};
  padding-bottom: 50px;
  padding-left: ${({ isDesktop }) => isDesktop ? '30px' : '18px'};
  padding-right: ${({ isDesktop }) => isDesktop ? '30px' : '18px'};
  background-color: white;
  color: black;
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2em;
`;

const Subtitle = styled.div`
  font-family: 'Montserrat', sans-serif;
  // font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: grey;
`;

const Subtitle2 = styled.div`
  font-family: 'Lora', serif;
  font-weight: 600;
  font-size: 16px;
`;

const Subtitle3 = styled.div`
  font-family: 'Montserrat', sans-serif;
  // font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: grey;
`;

const Subtitle4 = styled.div`
  font-family: 'Lora', serif;
  font-weight: 600;
  font-size: 14px;
  color: rgb(47, 47, 47);
`;

const Arrangement = styled.div`
  display: flex;
  flex-direction: ${({ isDesktop }) => isDesktop ? 'row' : 'column'};
`;

const Section1 = styled.div`
  width: ${({ isDesktop }) => isDesktop ? '50%' : '100%'};
  // background-color: red;

  // Center
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section2 = styled.div`
  padding-top: ${({ isDesktop }) => isDesktop ? '50px' : '0px'};
  width: ${({ isDesktop }) => isDesktop ? '50%' : '100%'};
  // background-color: blue;

  // Center
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemPhoto = styled.img`
  width: 320px;
  height: 320px;
  object-fit: cover;
  border-radius: 8px;
`;

const WrittenContent = styled.div`
  width: ${({ isDesktop }) => isDesktop ? '80%' : '90%'};
  font-family: 'Lora', serif;
  font-size: 18px;
`;

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

export default function IndividualWork() {
  const { company } = useParams()
  const history = useHistory()
  let [isDesktop, setIsDesktop] = useState(getWidth() > 1000);

  useEffect(() => {
    const resizeListener = () => {
      setIsDesktop(getWidth() > 1000)
    };
    window.addEventListener('resize', resizeListener);
    return () => { window.removeEventListener('resize', resizeListener); }
  }, [])

  // Vars
  let renderableContent

  if (company == 'intel') {
    document.title = "Intel | My Work";
    renderableContent = (<>
      <Arrangement isDesktop={isDesktop}>
        <Section1 isDesktop={isDesktop}>
          <ItemPhoto src={IntelImg} isDesktop={isDesktop}/>
        </Section1>
        <Section2 isDesktop={isDesktop}>
          <WrittenContent isDesktop={isDesktop}>
            <Title>Intel Corporation</Title>
            <div style={{height: "20px"}} />
            <Subtitle>Sales & Marketing Rotational Program</Subtitle>
            <div style={{height: "20px"}} />
            <Subtitle2>August 2021 - October 2022</Subtitle2>
            <div style={{height: "60px"}} />
            <Subtitle3>Sales and Marketing Development Manager (Team Cisco)</Subtitle3>
            <div style={{height: "20px"}} />
            <Subtitle4>May 2022 - October 2022</Subtitle4>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Create and execute go-to-market co-marketing strategies between Intel and Cisco, coordinating and facilitating integrated projects
including newsletters, PR/comms pieces, technical sales assets. Manage 5+ ecosystem partners in augmented reality, drone, and
federal/defense industries for co-marketing opportunities and sponsorships.</li>
              <br />
              <li>Build strategies to identify and engage customers to maximize ecosystem impact; Use solution approach to selling and creating value
for customers in big data and Cloud space facilitating strong customer relationships with CIOs, stakeholders.</li>
              <br />
              <li>Manage end-to-end webinar program operations from webinar creations, technical operations, building demand gen programs.</li>
            </ul>
            <div style={{height: "40px"}} />
            <Subtitle3>Small Business Marketing Strategist</Subtitle3>
            <div style={{height: "20px"}} />
            <Subtitle4>January 2022 - May 2022</Subtitle4>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Serving as voice of the end business customer (SMB small and medium businesses) and all value-chain players into the Commercial
PC and laptop business. Headed four-month long UX research for five verticals in small business segment.</li>
              <br />
              <li>Lead insights initiative to guide next-gen product innovation for Intel’s vPro client computing solutions. Identified and researched
three new market segments through target audience segmentation and profiling, value proposition, and feature prioritization with a
focus on the SMB small and medium business audience.</li>
            </ul>
            <div style={{height: "40px"}} />
            <Subtitle3>Executive Thought Leadership Marketing</Subtitle3>
            <div style={{height: "20px"}} />
            <Subtitle4>August 2021 - January 2022</Subtitle4>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Developed insights-led campaign for two mixed-method research studies gathering data on employee sentiment, executive socials
and compete marketing. Identified six gaps and devised quantifiable solutions, presented to Client Computing Group’s executive
team for growth opportunities to guide Intel strategy.</li>
              <br />
              <li>Drove internal communications as second level to Chief of Staff to drive strategic objectives in thought leadership: conducting
market research, driving executive-level outbound marketing content, building relationships with executive peer partners to support
co-marketing opportunities in technology, product, and solution development.</li>
            </ul>
          </WrittenContent>
        </Section2>
      </Arrangement>
    </>)
  } else if (company == 'globalfoundries') {
    document.title = "GlobalFoundries | My Work";
    renderableContent = (<>
      <Arrangement isDesktop={isDesktop}>
        <Section1 isDesktop={isDesktop}>
          <ItemPhoto src={GfImg} isDesktop={isDesktop}/>
        </Section1>
        <Section2 isDesktop={isDesktop}>
          <WrittenContent isDesktop={isDesktop}>
            <Title>GlobalFoundries</Title>
            <div style={{height: "20px"}} />
            <Subtitle>Product Marketing & Sales Enablement Intern</Subtitle>
            <div style={{height: "20px"}} />
            <Subtitle2>Summer 2020</Subtitle2>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Conducted market research on semiconductor foundry industry creating customer personas (OEM, sales funnels, ecosystem,
                  commercial, consumer) and trained sales teams for solutions-based selling methodology to accelerate and close opportunities.</li>
              <br />
              <li>Identified and led marketing analysis project using data insights to create sales and marketing digital collateral.</li>
            </ul>
          </WrittenContent>
        </Section2>
      </Arrangement>
    </>)
  } else if (company == 'ariana-kim-consulting') {
    document.title = "Ariana Kim Consulting | My Work";
    renderableContent = (<>
      <Arrangement isDesktop={isDesktop}>
        <Section1 isDesktop={isDesktop}>
          <ItemPhoto src={AkcImg} isDesktop={isDesktop}/>
        </Section1>
        <Section2 isDesktop={isDesktop}>
          <WrittenContent isDesktop={isDesktop}>
            <Title>Ariana Kim Consulting</Title>
            <div style={{height: "20px"}} />
            <Subtitle>Founder & Managing Partner</Subtitle>
            <div style={{height: "20px"}} />
            <a href="http://arianakimconsulting.com">arianakimconsulting.com</a>
            <div style={{height: "20px"}} />
            <Subtitle2>July 2021 - Present</Subtitle2>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Private education consultant servicing high school students and families to reach higher education goals including competitive college admissions and career placement.</li>
              <br />
              <li>Facilitating complex college admissions process with expert industry knowledge (over 7+ years in college admissions industry), 93% of students admitted to top-50 US colleges.</li>
            </ul>
          </WrittenContent>
        </Section2>
      </Arrangement>
    </>)
  } else if (company == 'samsung') {
    document.title = "Samsung | My Work";
    renderableContent = (<>
      <Arrangement isDesktop={isDesktop}>
        <Section1 isDesktop={isDesktop}>
          <ItemPhoto src={SamsungImg} isDesktop={isDesktop}/>
        </Section1>
        <Section2 isDesktop={isDesktop}>
          <WrittenContent isDesktop={isDesktop}>
            <Title>Samsung</Title>
            <div style={{height: "20px"}} />
            <Subtitle>Customer & Market Insights Intern</Subtitle>
            <div style={{height: "20px"}} />
            <Subtitle2>Summer 2019</Subtitle2>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Developed content strategy plan for multilingual sales enablement kit (Korean and English), working with internal stakeholders and
                  OEM partners for Foundry sales team targeting NA, PRC, and EU for Tier 1-3 customers in executive level.</li>
              <br />
              <li>Devised research analysis on Foundry market trends, customer behaviors, and customer moves for customer excellence project.
                  Leveraged data-driven insights to drive strategy cross-functionally with global sales engineers and product managers.</li>
            </ul>
          </WrittenContent>
        </Section2>
      </Arrangement>
    </>)
  } else if (company == 'city-national-bank') {
    document.title = "City National Bank | My Work";
    renderableContent = (<>
      <Arrangement isDesktop={isDesktop}>
        <Section1 isDesktop={isDesktop}>
          <ItemPhoto src={CnbImg} isDesktop={isDesktop}/>
        </Section1>
        <Section2 isDesktop={isDesktop}>
          <WrittenContent isDesktop={isDesktop}>
            <Title>City National Bank + Los Angeles Cyber Lab</Title>
            <div style={{height: "20px"}} />
            <Subtitle>Cybersecurity Marketing & Campaign Coordinator Fellow</Subtitle>
            <div style={{height: "20px"}} />
            <Subtitle2>August 2019 - November 2019</Subtitle2>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Coordinated 3+ cybersecurity in-person events and 5 webinars connecting ecosystem partners with LA Cyber Labs and City of Los
                  Angeles; assisted with content strategy selection, ensuring vendor invoices for events with over 10,000+ attendees and identifying
                  appropriate webinar digital platforms.</li>
              <br />
              <li>Collaborated with marketing, communications, and PM teams to develop branding and product positioning/messaging of LA Cyber
                  Labs available suite; developed go to market strategy and KPIs to assess effectiveness.</li>
            </ul>
          </WrittenContent>
        </Section2>
      </Arrangement>
    </>)
  } else if (company == 'nbc-universal') {
    document.title = "NBC Univeral | My Work";
    renderableContent = (<>
      <Arrangement isDesktop={isDesktop}>
        <Section1 isDesktop={isDesktop}>
          <ItemPhoto src={NbcUniversalImg} isDesktop={isDesktop}/>
        </Section1>
        <Section2 isDesktop={isDesktop}>
          <WrittenContent isDesktop={isDesktop}>
            <Title>NBC Universal Media</Title>
            <div style={{height: "20px"}} />
            <Subtitle>Campus Intern</Subtitle>
            <div style={{height: "20px"}} />
            <Subtitle2>August 2018 - October 2018</Subtitle2>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Designed and authored content for stakeholder presentations, internal digital engagement projects, outbound university recruitment with an outcome of 35% increased attendance for in-person events.</li>
              <br />
              <li>Spearheaded brand imaging campaigns and drove new initiatives in recruiting university students for NBCUniversal’s internship program.</li>
              <br />
              <li>Created digital marketing campaigns to maximize internal company engagement and identified growth gaps through collected data</li>
            </ul>
          </WrittenContent>
        </Section2>
      </Arrangement>
    </>)
  } else if (company == 'pac-rim') {
    document.title = "Pac Rim Marketing | My Work";
    renderableContent = (<>
      <Arrangement isDesktop={isDesktop}>
        <Section1 isDesktop={isDesktop}>
          <ItemPhoto src={PacRimImg} isDesktop={isDesktop}/>
        </Section1>
        <Section2 isDesktop={isDesktop}>
          <WrittenContent isDesktop={isDesktop}>
            <Title>PacRim Marketing Group</Title>
            <div style={{height: "20px"}} />
            <Subtitle>Integrated Marketing Intern</Subtitle>
            <div style={{height: "20px"}} />
            <Subtitle2>Summer 2018</Subtitle2>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Calculated and programmed targeted market segment strategies for East Asian luxury market, managing accounts Tiffany & Co, The Four Seasons, and UNIQLO for international business development.</li>
              <br />
              <li>Directed and executed unique experiential marketing events based on devised insights, bringing data-driven decisions to life. Produced visual and written content published in global newsletters, press releases, and web with total generated hits of over 5.5 million over 12-week span.</li>
            </ul>
          </WrittenContent>
        </Section2>
      </Arrangement>
    </>)
  } else if (company == 'flex') {
    document.title = "Flex | My Work";
    renderableContent = (<>
      <Arrangement isDesktop={isDesktop}>
        <Section1 isDesktop={isDesktop}>
          <ItemPhoto src={FlexImg} isDesktop={isDesktop}/>
        </Section1>
        <Section2 isDesktop={isDesktop}>
          <WrittenContent isDesktop={isDesktop}>
            <Title>Flex College Consulting</Title>
            <div style={{height: "20px"}} />
            <Subtitle>Associate Consultant</Subtitle>
            <div style={{height: "20px"}} />
            <Subtitle2>March 2020 - June 2021</Subtitle2>
            <div style={{height: "20px"}} />
            <ul>
              <br />
              <li>Lead in-person event and webinar presentations, conversations, and deals as an education industry subject matter expert; foster
                  and expand company’s relationships with partner companies (B2B commercial), and pitch/work directly with customers (B2C
                  consumer).</li>
              <br />
              <li>Achieved 175% of quota through excellent customer needs identification and sales strategies to accelerate and close opportunities
                  at 87% success through consultative solutions sales practices; Own all operational communications.</li>
            </ul>
          </WrittenContent>
        </Section2>
      </Arrangement>
    </>)
  } else {
    return (<Redirect to="/404" />)
  }

  return (<>
    <NavBar />
    <Outer isDesktop={isDesktop}>
      {renderableContent}
    </Outer>
  </>)
}
