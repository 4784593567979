import React, { Component } from "react"
import styled from "styled-components"
import { Col } from "react-bootstrap"
import NavBar from "../components/navbar.js"
import emailjs from 'emailjs-com';

const Outer = styled.div`
  animation: fade 1.0s ease-in;

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }
  position: relative;
  padding: 50px;
  color: black;
  background-color: white;
`;

const Title = styled.div`
  text-align: center;
  /* color: #1A344B; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 36px;
`;

const Subtitle = styled.div`
  color: #373737;
  font-family: 'Lora', serif;
  text-align: center;
`;

const CenterItem = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormInput = styled.input`
  width: ${({ isDesktop }) => isDesktop ? '640px' : '320px'};
  height: 60px;

  padding: 16px;
  background-color: #FFFFFF;
  /* box-shadow: 0px 5px 25px rgba(158, 167, 179, 0.35); */
  box-shadow: 0 0px 5px rgba(158, 167, 179, 0.35);
  border-radius: 8px;
  border: 0px solid;
  font-family: 'Lora', serif;
  font-size: 18px;

  ::placeholder {
    color: grey;
    opacity: 1;
  }
`;

const FormTextArea = styled.textarea`
  min-width: ${({ isDesktop }) => isDesktop ? '640px' : '320px'};
  height: 300px;

  padding: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 0px 5px rgba(158, 167, 179, 0.35);
  /* box-shadow: 0px 5px 25px rgba(158, 167, 179, 0.35); */
  border-radius: 8px;
  border: 0px solid;
  font-family: 'Lora', serif;
  font-size: 18px;

  ::placeholder {
    color: grey;
    opacity: 1;
  }
`;

const SendButton = styled.input`
  width: 200px;
  height: 56px;

  background: black;
  color: white;
  border-radius: 4px;
  border: 0px solid;

  :hover {
    /* @keyframes fade {
      0% {
        opacity: 0;
      }
    } */
    background: white;
    color: black;
    border: 2px solid;
  }

  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 18px;
`;

const ErrorMessage = styled.div`
  font-family: 'Lora', serif;
  font-size: 18px;
  color: red;
  text-align: center;
`;

export default class Contact extends Component {
  constructor() {
    super();
    document.title = "Let's connect!";
    this.state = {
      name: '',
      email: '',
      phone: '',
      text: '',
      errorMessage: '',
      isDesktop: true,
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.handleWindowChange = this.handleWindowChange.bind(this);
    this.setError = this.setError.bind(this);
    window.addEventListener("resize", this.handleWindowChange);
  }

  componentDidMount() {
    this.handleWindowChange()
  }

  handleChangeName(event) { this.setState({name: event.target.value}); }
  handleChangeEmail(event) { this.setState({email: event.target.value}); }
  handleChangePhone(event) { this.setState({phone: event.target.value}); }
  handleChangeText(event) { this.setState({text: event.target.value}); }
  setError(str) { this.setState({errorMessage: str}); }
  handleWindowChange(obj) { this.setState({isDesktop: window.innerWidth > 1000}); }

  // TODO: prevent multiple submission.
  async handleSubmission(event) {
    event.preventDefault()
    this.setError('')
    if (this.state.name.length === 0) {
      this.setError('Send failure: Name cannot be blank.')
      return
    }
    if (this.state.email.length === 0) {
      this.setError('Send failure: Email cannot be blank.')
      return
    }
    if (this.state.text.length === 0) {
      this.setError('Send failure: Message cannot be blank.')
      return
    }

    emailjs.send("ariana-email-service","template_kbzt8eo",{
      from_name: this.state.name,
      from_email: this.state.email,
      from_phone: this.state.phone.length === 0 ? 'Not specified' : this.state.phone,
      message: this.state.text,
    }, 'user_i7ghsjk9ADrJcZ0r7MgYG').then((result) => {
      this.props.history.push('/message-sent')
    }, (error) => {
      this.setError('Send failure: ' + error.text)
    });
  }

  render() {
    return (
      <>
        <Col style={{padding: "0"}}>
          <NavBar />
          <Outer>
            <Col style={{padding: "0"}}>
              <Title>Let's Connect!</Title>
              <div style={{height: "30px"}} />
              <Subtitle>Send me a message below and I'll try to get back to you ASAP!</Subtitle>
              <div style={{height: "30px"}} />
              <CenterItem>
                <form onSubmit={this.handleSubmission}>
                  <FormInput
                      isDesktop={this.state.isDesktop}
                      type="text"
                      placeholder="Name"
                      value={this.state.name}
                      onChange={this.handleChangeName} />
                  <div style={{height: "14px"}} />
                  <FormInput
                      isDesktop={this.state.isDesktop}
                      type="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleChangeEmail} />
                  <div style={{height: "14px"}} />
                  <FormInput
                      isDesktop={this.state.isDesktop}
                      type="text"
                      placeholder="Phone (optional)"
                      value={this.state.phone}
                      onChange={this.handleChangePhone} />
                  <div style={{height: "14px"}} />
                  <FormTextArea
                      isDesktop={this.state.isDesktop}
                      type="text"
                      placeholder="Message"
                      value={this.state.text}
                      onChange={this.handleChangeText} />
                  <div style={{height: "30px"}} />
                  <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
                  <div style={{height: "30px"}} />
                  <CenterItem><SendButton type="submit" value="Send Message" /></CenterItem>
                </form>
              </CenterItem>
              <div style={{height: "50px"}} />
            </Col>
          </Outer>
        </Col>
      </>
    )
  }
}
