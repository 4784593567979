import React from "react"
import styled from "styled-components"

const Outer = styled.div`
  padding: 30px;
  background-color: black;
  color: white;
`;

const List = styled.ol`
  text-decoration: none;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Montserrat', sans-serif;
  a {
    color: white;
  }
  a:hover {
    color: white;
  }
`;

const LinkList = styled.ol`
  text-decoration: none;
  padding-left: 10px;
  padding-right: 20px;
  position: sticky;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
`;

const Link = styled.a`
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  :hover {
    color: white;
  }
  text-transform: uppercase;
  letter-spacing: 0.2rem;
`;

export default function Footer() {
  return (
    <>
      <Outer>
        <LinkList>
          <Link href="/">Home</Link>
          <Link href="/services">Services</Link>
          <Link href="/about">About</Link>
          <Link href="/contact">Contact</Link>
          <Link href="/pay">Pay</Link>
        </LinkList>
        <div style={{height: "20px"}} />
        <List>
          <li style={{fontWeight: "bold"}}>© {new Date().getFullYear()} Ariana Kim Consulting</li>
          <li style={{fontFamily: "'Lora', serif"}}>Located in Santa Clara, CA</li>
        </List>
      </Outer>
    </>
  )
}
