import React, { } from "react"
import styled from "styled-components"
import { Col } from "react-bootstrap"
import NavBar from "../components/navbar.js"

const Outer = styled.div`
  animation: fade 1.0s ease-in;

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: ${({ isDesktop }) => isDesktop ? '30px' : '16px'};
  padding-right: ${({ isDesktop }) => isDesktop ? '30px' : '16px'};
  background-color: white;
  color: black;
`;

const Title = styled.div`
  text-align: center;
  /* color: #1A344B; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 36px;
`;

const Subheading = styled.div`
  color: black;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: #373737;
  font-size: 18px;
  font-family: 'Lora', serif;
`;

export default function MessageSent() {

  return (
    <>
      <Col style={{padding: "0"}}>
        <NavBar />
        <Outer>
          <div style={{height: "100px"}} />
          <Title>Message Sent!</Title>
          <div style={{height: "20px"}} />
          <Subheading>We'll be in touch shortly :-)</Subheading>
          <div style={{height: "200px"}} />
        </Outer>
      </Col>
    </>
  )
  // return html
}
